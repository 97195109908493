<template>
	<div class="container">
		<div class="container_head_img">
			<!-- <img src="../../assets/img/paihang_head.png" alt=""> -->
			<!-- <img src="../../assets/img/paihang_people.png" style="width: 100%;" alt=""> -->
			<img src="../../assets/img/paihang_title.png" style="width: 100%;" alt="">
		</div>
		<div class="container_head">
			<div class="container_head_button">
				<div class="container_head_button_l" @click="activeClick('1')" :class="{active: activeIndex == 1}">
					小学组<br/>
					<span>TOP2000</span>
				</div>
				<div class="container_head_button_l" @click="activeClick('2')" :class="{active: activeIndex == 2}">
					中学组<br/>
					<span>TOP2000</span>
				</div>
				<div class="container_head_button_l" @click="activeClick('3')" :class="{active: activeIndex == 3}">
					教师组<br/>
					<span>TOP300</span>
				</div>
				<div class="container_head_button_l" @click="activeClick('0')" :class="{active: activeIndex == 0}">
					各学校实时<br/>参赛人数
					<span>TOP100</span>
				</div>
			</div>
		</div>

		<template v-if="activeIndex == 1 || activeIndex == 2 || activeIndex == 3">
			<div class="container_box">
				<van-row class="container_box_title" type="flex" justify="space-around">
					<van-col>排名</van-col>
					<van-col>用户</van-col>
					<!-- <van-col>学校</van-col> -->
					<van-col>耗时</van-col>
					<van-col>总分</van-col>
				</van-row>
				<!-- <van-row class="container_box_ph my_info" type="flex" justify="space-around" v-if="userInfo">
					<van-col class="container_box_ph_item po_r">
						<div>{{ userInfo.rank }}</div>
					</van-col>
					<van-col class="container_box_ph_item">
						{{ mingcheng(userInfo.nickname) }}
					</van-col>
					<van-col class="container_box_ph_item">
						{{ s_to_hs(userInfo.totaltime) }}
					</van-col>
					<van-col class="container_box_ph_item">
						{{ userInfo.points }}
					</van-col>
				</van-row> -->
				<template v-for="(item, index) in dataFrom[activeIndex-1].rankInfo">
					<van-row class="container_box_ph" type="flex" justify="space-around">
						<van-col class="container_box_ph_item po_r">
							<div class="No1" v-if="item.rank === 1"></div>
							<div class="No2" v-else-if="item.rank === 2"></div>
							<div class="No3" v-else-if="item.rank === 3"></div>
							<div v-else>{{ item.rank }}</div>
						</van-col>
						<van-col class="container_box_ph_item">
							{{ mingcheng(item.nickname) }}
						</van-col>
						<!-- <van-col class="container_box_ph_item">
							{{ item.school }}
						</van-col> -->
						<van-col class="container_box_ph_item">
							{{ s_to_hs(item.totaltime) }}
						</van-col>
						<van-col class="container_box_ph_item">
							{{ item.points }}
						</van-col>
					</van-row>
				</template>
			</div>
		</template>

		<template v-if="activeIndex == 0">
			<div class="container_box">
				<van-row class="container_box_title" type="flex" justify="space-around">
					<van-col>排名</van-col>
					<van-col>学校</van-col>
					<van-col>人数</van-col>
				</van-row>
				<template v-for="(item, index) in school_rank">
					<van-row class="container_box_ph" type="flex" justify="space-around">
						<van-col class="container_box_ph_item po_r">
							<div class="No1" v-if="item.rank === 1"></div>
							<div class="No2" v-else-if="item.rank === 2"></div>
							<div class="No3" v-else-if="item.rank === 3"></div>
							<div v-else>{{ item.rank }}</div>
						</van-col>
						<van-col class="container_box_ph_item">
							{{ item.name }}
						</van-col>
						<van-col class="container_box_ph_item">
							{{ item.count }}
						</van-col>
					</van-row>
				</template>
			</div>
		</template>
		<img src="../../assets/img/zbfw.png" style="width: 90%;margin: 20px auto 10px;display: block;" alt="">
	</div>
</template>

<script>
	import {
		getInfo,
		getRankInfo
	} from '@/api'
	import {
		Dialog,
	} from 'vant';
	export default {
		name: "index",
		data() {
			return {
				activeIndex: 1,
				dataFrom: [],
				school_rank: [],
				username: '',
				userInfo: '',
				flag: true
			}
		},
		created() {
			if (localStorage.getItem('token') == null) {
				this.goLogin()
			}else{
				this.get_Info()
				this.get_RankInfo()
			}
		},
		mounted() {
			// this.get_RankInfo()
		},
		methods: {
			goLogin(){
				Dialog.confirm({
						title: '提示',
						message: '未登录，请前往登录',
						showCancelButton: false,
					})
					.then(() => {
						this.$router.push({
							name: 'Index',
						})
					})
			},
			get_RankInfo() {
				getRankInfo().then(res => {
					if(res.data.code == 1){
						this.dataFrom = res.data.data.point_rank;
						this.school_rank = res.data.data.school_rank;
					}else{
						this.$toast(res.data.msg)
					}
				})
			},
			activeClick(index) {
				this.activeIndex = index
				this.userInfo = '';
				if(index!=0){
					this.dataFrom[this.activeIndex-1].rankInfo.forEach((item,index) => {
						if(item.nickname==this.username){
							this.userInfo = item
						}
					})
				}
			},
			mingcheng(e) {
				return e.substring(0, 1) + new Array(e.length).join('*')
			},
			s_to_hs(s) {
				//计算分钟
				//算法：将秒数除以60，然后下舍入，既得到分钟数
				let h
				h = Math.floor(s / 60)
				//计算秒
				//算法：取得秒%60的余数，既得到秒数
				s = s % 60
				//将变量转换为字符串
				h += ''
				s += ''
				//如果只有一位数，前面增加一个0
				h = (h.length == 1) ? '0' + h : h
				s = (s.length == 1) ? '0' + s : s
				return h + ':' + s
			},
			get_Info() {
				getInfo({
					token: localStorage.getItem('token')
				}).then(res => {
					this.username = res.data.data.nickname
				})
			},
		}
	}
</script>

<style scoped lang="scss">
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding: 0 23px 54px 23px;
		box-sizing: border-box;
		background: url(../../assets/img/allbanner.png) no-repeat center 0;
		background-size: cover;
		background-color: #2a7fd2;
		.left_icon {
			box-sizing: border-box;
			padding-top: 20px;
		}
		&_head_img{
			padding-top: 120px;
			img{
				display: block;
				margin: auto;
			}
		}
		&_head {
			padding: 0px 10px 0px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			box-sizing: border-box;
			color: #fff;
			font-size: 28px;
			// background-color: #1C8B94;
			border-radius: 20px 20px 0px 0px;
			padding-top: 40px;
			&_button {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-weight: 600;
				color: #b8b8b8;
				&_l {
					font-size: 28px;
					display: flex;
					align-items: center;
					justify-content: center;
					flex-direction: column;
					height: 110px;
					line-height: 1.3;
					width: 168px;
					border-radius: 10px 10px 0px 0px;
					text-align: center;
					color: #B8B8B8;
					background-image: linear-gradient(white,#D2D2D2);
					span{
						font-size: 20px;
					}
				}

				.active {
					background-image: linear-gradient(#FFCC43,#FFAA2E);
					color: white;
				}
			}

			&_item {
				width: 33%;
				box-sizing: border-box;
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				position: relative;

				.img_center {
					width: 156px;
					height: 156px;
				}

				.img_r {
					margin-top: 40px;
				}

				&_img_t {
					position: absolute;
					top: -40px;
					width: 250px;
					height: 250px;
				}

				&_img_c {
					position: absolute;
					top: -70px;
					width: 250px;
					height: 250px;
				}

				&_img {
					box-sizing: border-box;
					width: 134px;
					height: 134px;
					border: 3PX solid #dcdcdc;
					z-index: 1;
					border-radius: 50%;
					background-color: #fff;
				}

				&_title {
					box-sizing: border-box;
					margin-top: 26px;
					font-size: 24px;
					color: #fff;
				}

				&_s {
					font-size: 24px;
					color: #fff;
				}
			}
		}

		&_p {
			display: flex;
			box-sizing: border-box;
			align-items: center;
			background-color: #fff;
			padding: 33px 30px 33px 30px;
			border-radius: 10px;
			justify-content: space-between;

			img {
				width: 72px;
				height: 72px;
			}

			&_l {
				display: flex;
				align-items: center;
				box-sizing: border-box;

				&_ph {
					color: #fd8227;
					font-size: 16px;
					font-weight: 800;
				}

				&_img {
					width: 68px;
					height: 68px;
					background-color: deeppink;
					margin-left: 27px;
					margin-right: 39px;
					border-radius: 50%;
				}

				&_name {
					font-size: 32px;
					color: #333333;
				}
			}

			&_r {
				color: #fd8227;
				font-size: 28px;
				font-weight: 800;
				display: flex;

				div {
					padding-right: 20px;
					padding-left: 20px;
				}
			}
		}

		&_box {
			background-color: #fff;
			border-radius: 10px;
			padding: 33px 30px 33px 30px;
			height: 900px;
			overflow-y: scroll;
			.active {
				color: #fd8227 !important;
			}

			&_title {
				font-size: 28px;
				color: #333333;
				display: flex;
				justify-content: space-around;
				border-bottom: 1px solid #aeaeae;
				padding-bottom: 10px;
			}

			.container_box_title div:nth-child(2) {
				width: 11%;
				text-align: center;
			}

			&_ph {
				width: 100%;
				font-size: 28px;
				color: #333333;
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-top: 60px;

				.po_r {
					position: relative;
				}

				&_item {
					width: 20%;
					text-align: center;

					img {
						width: 72px;
						height: 72px;
					}

					.po_a {
						position: absolute;
						top: 34px;
						left: 58px;
					}
				}

				&_item2 {
					width: 33.33%;
					text-align: center;

					img {
						width: 72px;
						height: 72px;
					}

					.po_a {
						position: absolute;
						top: 30px;
						left: 72px;
					}
				}

				/* &_item:nth-child(2) {
        width: 10%;
        text-align: center;
      }*/
				.item_flex {
					display: flex;
					align-items: center;
					justify-content: center;
				}

				&_img {
					width: 68px;
					height: 68px;
					background-color: deeppink;
					border-radius: 50%;
					margin-right: 10px;
				}
			}
			.my_info{
				width: 100%;
				font-size: 28px;
				color: #333333;
				display: flex;
				justify-content: space-around;
				align-items: center;
				margin-top: 0px;
				height: 90px;
				border-bottom: 1px solid #aeaeae;
			}
			.No1 {
				font-size: 36px;
				width: 40px;
				height: 65px;
				background: url(../../assets/img/one.png) no-repeat;
				background-size: contain;
				color: #fff;
				margin: -30px auto 0 auto;
			}

			.No2 {
				font-size: 36px;
				width: 40px;
				height: 65px;
				background: url(../../assets/img/two.png) no-repeat;
				background-size: contain;
				color: #fff;
				margin: -30px auto 0 auto;
			}

			.No3 {
				font-size: 36px;
				width: 40px;
				height: 65px;
				background: url(../../assets/img/three.png) no-repeat;
				background-size: contain;
				color: #fff;
				margin: -30px auto 0 auto;
			}
		}
	}
</style>
